<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.distressalerts.missionReportAction.title')"
			:modelName="modelName"
			:dialogName="dialogmissionReport"
			:widthDialog="1000"
			:onOk="onOkMissionreport"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<!-- DISTRESSID -->

					<v-col cols="12">
						<pui-text-area
							:id="`missionreport-distressalerts`"
							v-model="data.modalData.missionreport"
							:label="$t('distressalerts.missionreport')"
							required
							toplabel
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.distressalerts.incidentReportAction.title')"
			:modelName="modelName"
			:dialogName="dialogNameClose"
			:widthDialog="1000"
			:onOk="onOkIncidentreport"
			:onShow="onShowClose"
		>
			<template slot="message" slot-scope="data">
				<v-container>{{ $t('modal.distressalerts.incidentReportAction.text') }}</v-container>
				<v-row dense>
					<!-- DATECLOSURE -->
					<v-col cols="6">
						<pui-date-field
							:id="`dateclosure-distressalerts`"
							v-model="data.modalData.dateclosure"
							:label="$t('distressalerts.dateclosure')"
							required
							toplabel
							time
							:max="new Date()"
							:min="data.modalData.date"
						></pui-date-field>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="getTitleAutoritiestonotify">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-immigration-${modelName}`"
										:label="$t('modal.distressalerts.incidentReportAction.checkvtso')"
										v-model="checkboxGroup.checkboxvtso"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-customs-${modelName}`"
										:label="$t('modal.distressalerts.incidentReportAction.checkhama')"
										v-model="checkboxGroup.checkboxhama"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-harbour-master-${modelName}`"
										:label="$t('modal.distressalerts.incidentReportAction.checkjmoc')"
										v-model="checkboxGroup.checkboxjmoc"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-harbour-master-${modelName}`"
										:label="$t('modal.distressalerts.incidentReportAction.checkcgof')"
										v-model="checkboxGroup.checkboxcgof"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
					</v-col>

					<!-- incidentreport -->
					<v-col cols="12">
						<pui-text-area
							:id="`incidentreport-distressalerts`"
							v-model="data.modalData.incidentreport"
							:label="$t('distressalerts.incidentreport')"
							required
							toplabel
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'distressalerts-modals',
	data() {
		return {
			dialogmissionReport: 'missionReportAction',
			dialogincidentReport: 'incidentReportAction',
			dialogNameClose: 'incidentReportAction',
			checkboxGroup: {
				checkboxvtso: false,
				checkboxhama: false,
				checkboxcgof: false,
				checkboxjmoc: false
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkMissionreport(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.missionreport;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.distressalerts.missionReportAction.title');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				distressid: modalData.headerPk.distressid
			};
			const patchData = {
				missionreport: modalData.missionreport
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						//this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkIncidentreport(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.incidentreport;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.distressalerts.incidentReportAction.title');
			const message = this.$puiI18n.t('puiaction.notifySuccess');

			const data = {
				distressid: modalData.headerPk.distressid,
				incidentreport: modalData.incidentreport,
				dateClosure: modalData.dateclosure,
				vtso: this.checkboxGroup.checkboxvtso,
				hama: this.checkboxGroup.checkboxhama,
				jmoc: this.checkboxGroup.checkboxjmoc,
				cgof: this.checkboxGroup.checkboxcgof
			};

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					data,
					() => {
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						//this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onShowClose(modalData) {
			modalData.incidentreport = null;
		}
	},
	computed: {
		getTitleAutoritiestonotify() {
			return this.$t('modal.distressalerts.incidentReportAction.titleauthories');
		}
	}
};
</script>
