<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<distressalerts-form-header :modelPk="modelPk"></distressalerts-form-header>
		</pui-form-header>
		<distressalerts-modals :modelName="modelName"></distressalerts-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#main'">{{ $t('distressalerts.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#distressalertssitrep'" v-if="!isCreatingElement">{{ $t('distressalerts.distressalertssitrep') }}</v-tab>
				<v-tab :key="2" :href="'#missionreport'" v-if="model.missionreport">{{ $t('distressalerts.missionreport') }}</v-tab>
				<v-tab :key="3" :href="'#incidentreport'" v-if="model.incidentreport">{{ $t('distressalerts.incidentreport') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'main'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<!-- TYPECODE -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-select
										:id="`typecode-distressalerts`"
										:attach="`typecode-distressalerts`"
										:label="$t('distressalerts.typecode')"
										:disabled="this.model.statuscode == 'C'"
										required
										toplabel
										clearable
										modelName="vlupmdistressalertstypes"
										v-model="model"
										reactive
										:itemsToSelect="typecodeItemsToSelect"
										:modelFormMapping="{ typecode: 'typecode' }"
										:itemValue="['typecode']"
										:itemText="(item) => `${item.typedesc}`"
									></pui-select>
								</v-col>
								<!-- DATE -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-date-field
										:id="`date-distressalerts`"
										v-model="model.date"
										:label="$t('distressalerts.date')"
										:disabled="this.model.statuscode == 'C'"
										required
										toplabel
										time
										:max="new Date()"
									></pui-date-field>
								</v-col>

								<!-- COMSOURCECODE -->
								<v-col
									:class="
										!this.model.dateclosure
											? 'col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3'
											: 'col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3'
									"
								>
									<pui-select
										:id="`comsourcecode-distressalerts`"
										:attach="`comsourcecode-distressalerts`"
										:label="$t('distressalerts.comsourcecode')"
										:disabled="this.model.statuscode == 'C'"
										required
										toplabel
										clearable
										modelName="vlupmdistressalertscs"
										v-model="model"
										reactive
										:itemsToSelect="comsourcecodeItemsToSelect"
										:modelFormMapping="{ comsourcecode: 'comsourcecode' }"
										:itemValue="['comsourcecode']"
										:itemText="(item) => `${item.comsourcecode} - ${item.comsourcedesc}`"
									></pui-select>
								</v-col>

								<!-- DATECLOSURE -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="this.model.dateclosure">
									<pui-date-field
										:id="`dateclosure-distressalerts`"
										v-model="model.dateclosure"
										:label="$t('distressalerts.dateclosure')"
										disabled
										toplabel
										time
										:min="this.model.date"
									></pui-date-field>
								</v-col>
							</v-row>
							<pui-field-set
								:title="$t('distressalerts.notifyto')"
								style="margin-top: 15px; margin-bottom: 15px"
								v-if="isCreatingElement"
							>
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
										><pui-checkbox
											:id="`checkbox-notifyvtso-${modelName}`"
											:label="$t('distressalerts.notifyvtso')"
											v-model="model.notifyvtso"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
										><pui-checkbox
											:id="`checkbox-notifyhama-${modelName}`"
											:label="$t('distressalerts.notifyhama')"
											v-model="model.notifyhama"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
										><pui-checkbox
											:id="`checkbox-notifyjmoc-${modelName}`"
											:label="$t('distressalerts.notifyjmoc')"
											v-model="model.notifyjmoc"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
										><pui-checkbox
											:id="`checkbox-harbour-master-${modelName}`"
											:label="$t('distressalerts.notifycgof')"
											v-model="model.notifycgof"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
								</v-row>
							</pui-field-set>

							<pui-field-set :title="$t('distressalerts.shipinfo')">
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.shipid')"
											toplabel
											clearable
											reactive
											:disabled="this.model.statuscode == 'C'"
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'version' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.imo} - ${item.shipname}`"
											:order="{ shipname: 'asc' }"
											showOnlyActives
											:fixedFilter="filteractiveShips"
											@change="onChangeShipId($event)"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense v-if="this.model.shipid != null">
									<!-- VESSELNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.vesselname')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.shipname}`"
											:key="shipKey"
										></pui-select>
									</v-col>

									<!-- IMO -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.imo')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.imo}`"
											:key="shipKey"
										></pui-select>
									</v-col>
									<!-- callsign -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.callsign')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.callsign}`"
											:key="shipKey"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.flag')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.flag} - ${item.flagdesc} `"
											:key="shipKey"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense v-else>
									<!-- VESSELNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`vesselname-distressalerts`"
											v-model="model.vesselname"
											:label="$t('distressalerts.vesselname')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
									<!-- IMO -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`imo-distressalerts`"
											required
											v-model="model.imo"
											:label="$t('distressalerts.imo')"
											:disabled="this.model.statuscode == 'C'"
											toplabel
											maxlength="7"
										></pui-text-field>
									</v-col>
									<!-- CALLSIGN -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`callsign-distressalerts`"
											v-model="model.callsign"
											:label="$t('distressalerts.callsign')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											maxlength="7"
										></pui-text-field>
									</v-col>
									<!-- FLAG -->
									<v-col class="col-12 col-xs-12 col-sm-12col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`flag-distressalerts`"
											:attach="`flag-distressalerts`"
											:label="$t('distressalerts.flag')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											:itemsToSelect="flagItemsToSelect"
											:modelFormMapping="{ countrycode: 'flag' }"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'distressalertssitrep'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="distressalertssitrep-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ distressid: 'distressid' }"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="'missionreport'" lazy>
					<v-col cols="12">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<pui-text-area
									:id="`missionreport-distressalerts`"
									v-model="model.missionreport"
									:label="$t('distressalerts.missionreport')"
									disabled
									required
									toplabel
									readOnly
								></pui-text-area>
							</v-col>
						</v-row>
					</v-col>
				</v-tab-item>
				<v-tab-item :key="3" :value="'incidentreport'" lazy>
					<v-col cols="12">
						<v-row dense>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<pui-text-area
									:id="`incidentreport-distressalerts`"
									v-model="model.incidentreport"
									:label="$t('distressalerts.incidentreport')"
									disabled
									required
									toplabel
									readOnly
								></pui-text-area>
							</v-col>
						</v-row>
					</v-col>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="this.model.statuscode == 'C'"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import distressalertsActions from './DistressalertsActions';
import distressalertsModals from './DistressalertsModals.vue';

export default {
	name: 'distressalerts-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'distressalerts-modals': distressalertsModals
	},
	data() {
		return {
			modelName: 'distressalerts',
			actions: distressalertsActions.formactions
		};
	},
	methods: {
		afterGetData() {},

		onChangeShipId(e) {
			if (e != null && e != undefined && e.shipid != null) {
				this.model.shipid = e.shipid;
				this.model.version = e.version;
				this.shipKey++;
			}
		}
	},
	computed: {
		itemsToSelectShipid() {
			return [{ shipid: this.model.shipid, version: this.model.version }];
		},
		statuscodeItemsToSelect() {
			return [{ statuscode: this.model.statuscode }];
		},
		typecodeItemsToSelect() {
			return [{ typecode: this.model.typecode }];
		},
		comsourcecodeItemsToSelect() {
			return [{ comsourcecode: this.model.comsourcecode }];
		},
		flagItemsToSelect() {
			return [{ countrycode: this.model.flag }];
		},
		filteractiveShips() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'inactive_ind', op: 'eq', data: 'No' }]
					}
				]
			};
		}
	},
	created() {},
	watch: {
		'model.shipid'() {
			if (this.model.shipid) {
				this.model.callsign = null;
				this.model.flag = null;
				this.model.vesselname = null;
				this.model.imo = null;
			}
		}
	}
};
</script>
