import utilActions from '@/api/actions';

const missionReportAction = {
	id: 'missionReportAction',
	selectionType: 'single',
	label: 'action.distressalerts.missionReportAction',
	functionality: 'INSERT_MISSION_REPORT',
	showInForm: true,
	checkAvailability: function (row) {
		return row && row[0].statuscode == 'O' && (row[0].missionreportstatus == 'Pending' || row[0].missionreportmodel == 'Pending');
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-missionReportAction-' + model.name + '-show', row);
	}
};

const incidentReportAction = {
	id: 'incidentReportAction',
	selectionType: 'single',
	label: 'action.distressalerts.incidentReportAction',
	functionality: 'INSERT_INCIDENT_REPORT',
	showInForm: true,
	checkAvailability: function (row) {
		return (
			row &&
			row[0].statuscode == 'O' &&
			((row[0].incidentreportstatus == 'Pending' && row[0].missionreportstatus == 'Submitted') ||
				(row[0].missionreportmodel == 'Submitted' && row[0].incidentreportmodel == 'Pending'))
		);
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-incidentReportAction-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [missionReportAction, incidentReportAction],
	formactions: [missionReportAction, incidentReportAction]
};
